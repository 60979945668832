<template>
  <div
    class="grid justify-items-stretch items-stretch th-grid-auto-rows-300 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6"
  >
    <div
      v-for="(item, index) in widgets"
      :key="`widget-${index}`"
      :class="{
        'row-span-2 sm:col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3':
          item.type === 'chart'
      }"
    >
      <component
        :is="item.component"
        :current-user-context="currentUserContext"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SalesToday: defineAsyncComponent(() =>
      import('../widget-types/SalesToday')
    ),
    SalesWeek: defineAsyncComponent(() => import('../widget-types/SalesWeek')),
    SalesRange: defineAsyncComponent(() =>
      import('../widget-types/SalesRange')
    ),
    TopCategories: defineAsyncComponent(() =>
      import('../widget-types/TopCategories')
    ),
    TopItems: defineAsyncComponent(() => import('../widget-types/TopItems')),
    TopPayment: defineAsyncComponent(() =>
      import('../widget-types/TopPayment')
    ),
    RevenuePerHour: defineAsyncComponent(() =>
      import('../widget-types/RevenuePerHour')
    ),
    RevenuePerDay: defineAsyncComponent(() =>
      import('../widget-types/RevenuePerDay')
    ),
    RevenuePerWeekDay: defineAsyncComponent(() =>
      import('../widget-types/RevenuePerWeekDay')
    )
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      widgets: [
        { type: 'box', component: 'SalesToday' },
        { type: 'box', component: 'SalesWeek' },
        { type: 'box', component: 'SalesRange' },
        { type: 'box', component: 'TopCategories' },
        { type: 'box', component: 'TopItems' },
        { type: 'box', component: 'TopPayment' },
        { type: 'chart', component: 'RevenuePerHour' },
        { type: 'chart', component: 'RevenuePerDay' },
        { type: 'chart', component: 'RevenuePerWeekDay' }
      ]
    }
  }
}
</script>

<style scoped>
.th-grid-auto-rows-300 {
  grid-auto-rows: 300px;
}
</style>
