<template>
  <th-page-wrapper background-color="#fafafa">
    <div v-if="!isStaff" class="w-full min-w-full p-6">
      <div class="flex items-center mb-6">
        <date-range-picker
          placement="bottom-start"
          :locale="locale"
          :model-value="datepickerDates"
          :date-format="dateFormat"
          no-future-dates
          @update:modelValue="updateDates"
          @clear="handleClear"
        />
        <th-currency-select
          v-show="currencies.length > 1"
          v-model="selectedCurrency"
          :class="[
            'w-20',
            { 'ml-auto mr-4': $isFeatureEnabled('development') }
          ]"
          :allowed-currencies="currencies"
        />
        <router-link
          :to="{ name: 'home-new' }"
          :class="`${currencies.length > 1 ? '' : 'ml-auto'}`"
        >
          <el-button
            plain
            @click="$ampli.eventWithBaseProps('homeNewDashboardClick')"
          >
            {{ $t('common.forms.labels.new_flow') }}
          </el-button>
        </router-link>
      </div>
      <widgets-container :current-user-context="currentUserContext" />
    </div>
    <div
      v-else
      class="w-full h-full min-w-full flex flex-col justify-evenly items-center"
    >
      <img :src="staffHome" alt="staff-home-welcome" />
      <div class="font-bold text-lg leading-4 text-th-primary">
        {{ $t('pages.home.staff.welcome.title') }}
      </div>
      <div class="staff-home-content text-base">
        {{ $t('pages.home.staff.welcome.content') }}
      </div>
    </div>
  </th-page-wrapper>
</template>

<script>
import get from 'just-safe-get'
import { mapGetters } from 'vuex'
import WidgetsContainer from './components/widgets-container'
import DateRangePicker from '@/components/date-picker/range'
import staffHome from '@/assets/images/staff-home.svg'

export default {
  name: 'Home',
  components: {
    WidgetsContainer,
    DateRangePicker
  },
  data() {
    return {
      dateFormat: this.$date.getDateFormat(),
      selectedDates: {},
      locale: this.$i18n.locale,
      selectedCurrency: '',
      staffHome
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isStaff: 'Auth/isStaff',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currentLocation: 'Config/getCurrentLocation',
      locationContext: 'Config/getLocationContext',
      currencies: 'Config/getAvailableCurrencies'
    }),
    datepickerDates() {
      return {
        ...this.selectedDates,
        showDateText: true
      }
    },
    currentBranchNumber() {
      return get(this.locationContext, 'branches.0.custom_id')
    },
    currentUserContext() {
      return {
        currentLocation: this.currentLocation,
        currentBranchNumber: this.currentBranchNumber,
        selectedDates: this.selectedDates,
        defaultCurrency: this.defaultCurrency,
        selectedCurrency: this.selectedCurrency
      }
    }
  },
  created() {
    this.updateDates()
    this.initSelectedCurrency()
  },
  methods: {
    updateDates(date = {}) {
      this.selectedDates = {
        start: date.start,
        end: date.end,
        showDateText: true
      }
    },
    initSelectedCurrency() {
      this.selectedCurrency = this.defaultCurrency
    },
    handleClear() {
      if (this.defaultShortCut) return
      this.updateDates()
    }
  }
}
</script>

<style scoped>
.staff-home-content {
  width: 444px;
  height: 54px;
}
</style>
